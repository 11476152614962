import { MenuItem, Select, InputLabel, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledSelectItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "1.1rem",
  color: "var(--color-crimson)",
  "&:hover": {
    backgroundColor: "var(--color-crimson)",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: "1.6rem",
  color: "var(--color-crimson)",
  fontWeight: 300,
  textAlign: "center",
  ".MuiSvgIcon-root ": {
    fill: "var(--color-crimson) !important",
  },
  ":before": { borderBottomColor: "var(--color-crimson)" },
  ":after": { borderBottomColor: "var(--color-crimson)" },
  "&:not(.Mui-disabled):hover::before": {
    borderColor: "var(--color-crimson)",
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "1rem",
  color: "var(--color-golden)",
  "&.Mui-focused": {
    color: "var(--color-golden)",
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  input: { textAlign: "center" },
  "& .MuiInput-underline:before": {
    borderBottomColor: "var(--color-crimson)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--color-crimson)",
  },
  "&& .MuiInput-root:hover::before": {
    borderColor: "var(--color-crimson)",
  },
}));

export const inputProps = {
  style: {
    fontSize: "1.6rem",
    color: "var(--color-crimson)",
    fontWeight: 300,
  },
};

export const inputLabelProps = {
  style: {
    fontSize: "1.2rem",
    color: "var(--color-crimson)",
  },
};

export const menuProps = {
  PaperProps: {
    sx: { backgroundColor: "var(--color-crimson)" },
  },
};

export const dtpProps = {
  style: {
    fontSize: "1.6rem",
    color: "var(--color-crimson)",
    fontWeight: 300,
  },
  sx: {
    "& .MuiInputBase-input": { padding: "5px" },
    "&:hover": {
      borderBottom: "2px solid var(--color-crimson)",
    },
    "& .MuiSvgIcon-root": {
      fill: "var(--color-crimson) !important",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderBottom: "1px solid var(--color-crimson)",
    },
  },
};

export const popperProps = {
  sx: {
    "& .MuiPaper-root": {
      border: "1px solid black",
      backgroundColor: "var(--color-gray)",
    },
    "& .MuiCalendarPicker-root": {
      backgroundColor: "var(--color-gray)",
    },
    "& .MuiPickersDay-dayWithMargin": {
      color: "var(--main-color-alt)",
      backgroundColor: "var(--color-crimson)",
      fontSize: "1rem",
    },
    "& .MuiDayPicker-weekDayLabel, ": {
      color: "var(--color-crimson)",
      fontSize: "0.8rem",
    },
    "& .MuiPickersCalendarHeader-label": {
      color: "var(--color-crimson)",
      fontSize: "1rem",
    },
    "& .MuiSvgIcon-root": {
      color: "var(--color-crimson)",
    },
  },
};
