import { React, useRef } from "react";

import { images } from "../../constants";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

import "./Gallery.css";

const Gallery = () => {
  const scrollRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 830;
    } else {
      current.scrollLeft += 830;
    }
  };

  const galleryImages = [
    images.img01,
    images.img02,
    images.img03,
    images.img04,
    images.img05,
    images.img06,
    images.img07,
    images.img08,
    images.img09,
    images.img10,
    images.img11,
    images.img12,
  ];

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="app__gallery flex__center app__bg" id="gallery">
      <div className="app__gallery-content">
        <h1 className="app__gallery-h1">Galéria</h1>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img
                src={image}
                alt="gallery"
                onClick={() => {
                  openInNewTab(image);
                }}
              />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};
export default Gallery;
