import bg from "../assets/bg.png";
import welcome from "../assets/welcome.jpg";
import karacsony from "../assets/karacsonyi_ajanlat.jpeg";
import img01 from "../assets/img01.jpg";
import img02 from "../assets/img02.jpg";
import img03 from "../assets/img03.jpg";
import img04 from "../assets/img04.jpg";
import img05 from "../assets/img05.jpg";
import img06 from "../assets/img06.jpg";
import img07 from "../assets/img07.jpg";
import img08 from "../assets/img08.jpg";
import img09 from "../assets/img09.jpg";
import img10 from "../assets/img10.jpg";
import img11 from "../assets/img11.jpg";
import img12 from "../assets/img12.jpg";

export default {
  bg,
  welcome,
  karacsony,
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
};
