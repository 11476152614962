import React from "react";

import "./Admin.css";

import Datatable from "../DataTable/DataTable";

const Admin = () => {
  return (
    <div>
      <div className="page app__bg">
        <Datatable className="menuTable" />
      </div>
    </div>
  );
};

export default Admin;
