import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { DataGrid } from "@mui/x-data-grid";
import { Box, IconButton, Button, Stack, Tooltip } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import "./DataTable.css";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMenu = async () => {
    if (data.length === 0) {
      try {
        const response = await axios.get(
          `https://muszalyvendeglo.herokuapp.com/getMenu`
        );
        const menu = response.data;
        setData(menu);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchMenu();
    // eslint-disable-next-line
  }, []);

  const deleteSelected = async (rowID) => {
    const filtered_data = data.filter((row) => row._id !== rowID);
    setData(filtered_data);
    try {
      axios
        .delete(`https://muszalyvendeglo.herokuapp.com/deleteMenu?id=${rowID}`)
        .then((response) => {
          console.log(response);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row">
            <Tooltip title="Menü szerkesztése">
              <IconButton
                component={Link}
                to={`/admin/editMenu/${cellValues.row._id}`}
                size="medium"
                color="info"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Menü törlése">
              <IconButton
                size="medium"
                color="error"
                onClick={() => {
                  deleteSelected(`${cellValues.row._id}`);
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const menuColumns = [
    {
      field: "year",
      headerName: "Év",
      width: 300,
      sortable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: "1.2rem" }}>{cellValues.value}</div>;
      },
    },
    {
      field: "week",
      headerName: "Hét",
      width: 260,
      sortable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: "1.2rem" }}>{cellValues.value}</div>;
      },
    },
    {
      field: "dateRange",
      headerName: "Dátum",
      width: 615,
      sortable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: "1.2rem" }}>{cellValues.value}</div>;
      },
    },
    {
      field: "_id",
      headerName: "ID",
      width: 500,
      sortable: false,
      renderCell: (cellValues) => {
        return <div style={{ fontSize: "1.2rem" }}>{cellValues.value}</div>;
      },
    },
  ];

  return (
    <Box className="table">
      <Box className="addButton">
        <Button className="menuAdd" component={Link} to="/admin/newMenu">
          Menü hozzáadása
        </Button>
        <Button className="menuAdd" component={Link} to="/admin/updatePrice">
          Menü ár frissítése
        </Button>
      </Box>
      <DataGrid
        sx={{
          color: "var(--color-crimson)",
          border: 0,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "var(--color-gray)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottom: 0,
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: 0,
          },

          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            fontSize: "1.1rem",
            color: "var(--color-crimson)",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row:hover": {
            // backgroundColor: "var(--color-black)",
            color: "var(--color-golden)",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--color-crimson)",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: 0,
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: 0,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "var(--color-gray)",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            fontSize: "1.3rem",
            color: "var(--color-crimson)",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "1.3rem",
            color: "var(--color-crimson)",
          },
        }}
        getRowId={(row) => row._id}
        rows={data}
        columns={menuColumns.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        rowHeight={62}
        disableSelectionOnClick
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={isLoading}
        disableColumnMenu
      />
    </Box>
  );
};

export default Datatable;
