import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import bcrypt from "bcryptjs";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Snackbar from "../../features/Snackbar";

import { Box, Grid, Button, Stack } from "@mui/material";
import {
  StyledInputLabel,
  StyledTextField,
  inputProps,
  inputLabelProps,
} from "../../components/Styling/CustomStyling";

import "./Login.css";

const Login = () => {
  const [show, setShow] = useState(true);

  const schema = yup.object().shape({
    username: yup.string().required("Név megadása kötelező!"),
    password: yup.string().required("Jelszó megadása kötelező!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const snackbarRef = useRef(null);

  //submit form
  const [sbMessage, setSbMessage] = useState("");
  const [sbType, setSbType] = useState("success");

  const onSubmit = async (data) => {
    setShow(false);
    try {
      axios
        .post("https://muszalyvendeglo.herokuapp.com/login", {
          username: data.username,
          password: data.password,
        })
        .then((response) => {
          const success = response.data.status;
          if (success === "ok") {
            localStorage.setItem("username", "admin");
            setSbMessage("SIKERES BEJELENTKEZÉS!");
            setSbType("success");
            snackbarRef.current.show();
            setTimeout(() => {
              window.location.href = "/admin";
              // navigate("/admin");
            }, 2000);
          } else {
            setSbMessage("SIKERTELEN BEJELENTKEZÉS!");
            setSbType("error");
            snackbarRef.current.show();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  // var hashPass;

  // bcrypt.genSalt(10, function (err, Salt) {
  //   bcrypt.hash("geloCplLcibUmJfeCN8x", Salt, function (err, hash) {
  //     hashPass = hash;
  //     console.log("pass:" + hashPass);
  //   });
  // });

  return (
    <Box className="app__bg section__padding container">
      <Box>
        <h1 className="app__header-h1 app__slider-h1">Bejelentkezés</h1>
      </Box>
      <Box className="box">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Stack spacing={2} width={500}>
              <StyledInputLabel>Név</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("username")}
              />
              <p className="error">{errors.username?.message}</p>

              <StyledInputLabel>Jelszó</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                type="password"
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("password")}
              />
              <p className="error">{errors.password?.message}</p>
              <Button type="submit" className="loginButton">
                Bejelentkezés
              </Button>
              <Snackbar ref={snackbarRef} message={sbMessage} type={sbType} />
            </Stack>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
