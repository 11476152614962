import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Snackbar from "../../features/Snackbar";

import { Box, Grid, Button, Stack } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  StyledInputLabel,
  StyledTextField,
  inputProps,
  inputLabelProps,
} from "../../components/Styling/CustomStyling";

import "./EditMenu.css";

const EditMenu = () => {
  let { id } = useParams();
  let isData = false;

  const { data } = useQuery(["getMenuById"], async () => {
    return axios
      .get(`https://muszalyvendeglo.herokuapp.com/getMenuByID?id=${id}`)
      .then((res) => res.data);
  });

  data === "" || data === "INVALID ID!" ? (isData = false) : (isData = true);

  const schema = yup.object().shape({
    dailyMenu1S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu2S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu3S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu4S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu5S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5C: yup.string().required("A mező kitöltése kötelező!"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const snackbarRef = useRef(null);
  const SnackBarType = {
    success: "success",
    fail: "fail",
  };

  const onSubmit = async (data) => {
    setShow(false);
    snackbarRef.current.show();
    try {
      axios
        .put("https://muszalyvendeglo.herokuapp.com/updateMenu", {
          id: id,
          dailyMenu1: {
            day: "Hétfő",
            date: getValues("day1Date"),
            soup: data.dailyMenu1S,
            menuA: data.dailyMenu1A,
            menuB: data.dailyMenu1B,
            menuC: data.dailyMenu1C,
          },
          dailyMenu2: {
            day: "Kedd",
            date: getValues("day2Date"),
            soup: data.dailyMenu2S,
            menuA: data.dailyMenu2A,
            menuB: data.dailyMenu2B,
            menuC: data.dailyMenu2C,
          },
          dailyMenu3: {
            day: "Szerda",
            date: getValues("day3Date"),
            soup: data.dailyMenu3S,
            menuA: data.dailyMenu3A,
            menuB: data.dailyMenu3B,
            menuC: data.dailyMenu3C,
          },
          dailyMenu4: {
            day: "Csütörtök",
            date: getValues("day4Date"),
            soup: data.dailyMenu4S,
            menuA: data.dailyMenu4A,
            menuB: data.dailyMenu4B,
            menuC: data.dailyMenu4C,
          },
          dailyMenu5: {
            date: getValues("day5Date"),
            day: "Péntek",
            soup: data.dailyMenu5S,
            menuA: data.dailyMenu5A,
            menuB: data.dailyMenu5B,
            menuC: data.dailyMenu5C,
          },
        })
        .then((response) => {
          snackbarRef.current.show();
          setTimeout(() => {
            navigate("/admin");
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (data) {
      setValue("day1Date", data.dailyMenu1.date);
      setValue("dailyMenu1S", data.dailyMenu1.soup);
      setValue("dailyMenu1A", data.dailyMenu1.menuA);
      setValue("dailyMenu1B", data.dailyMenu1.menuB);
      setValue("dailyMenu1C", data.dailyMenu1.menuC);

      setValue("day2Date", data.dailyMenu2.date);
      setValue("dailyMenu2S", data.dailyMenu2.soup);
      setValue("dailyMenu2A", data.dailyMenu2.menuA);
      setValue("dailyMenu2B", data.dailyMenu2.menuB);
      setValue("dailyMenu2C", data.dailyMenu2.menuC);

      setValue("day3Date", data.dailyMenu3.date);
      setValue("dailyMenu3S", data.dailyMenu3.soup);
      setValue("dailyMenu3A", data.dailyMenu3.menuA);
      setValue("dailyMenu3B", data.dailyMenu3.menuB);
      setValue("dailyMenu3C", data.dailyMenu3.menuC);

      setValue("day4Date", data.dailyMenu4.date);
      setValue("dailyMenu4S", data.dailyMenu4.soup);
      setValue("dailyMenu4A", data.dailyMenu4.menuA);
      setValue("dailyMenu4B", data.dailyMenu4.menuB);
      setValue("dailyMenu4C", data.dailyMenu4.menuC);

      setValue("day5Date", data.dailyMenu5.date);
      setValue("dailyMenu5S", data.dailyMenu5.soup);
      setValue("dailyMenu5A", data.dailyMenu5.menuA);
      setValue("dailyMenu5B", data.dailyMenu5.menuB);
      setValue("dailyMenu5C", data.dailyMenu5.menuC);
    }
  }, [data]);

  return (
    <Box className="editMenuContainer app__bg">
      {isData && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <Stack>
                <StyledInputLabel>Év</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  readOnly
                  value={data?.year}
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack>
                <StyledInputLabel>Hét</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  readOnly
                  value={data?.week}
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <StyledInputLabel>Dátum</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  readOnly
                  value={data?.dateRange}
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                />
              </Stack>
            </Grid>

            {/* hétfő */}
            <Grid
              container
              item
              xs={8}
              spacing={3}
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "3rem",
                borderBottom: "2px solid var(--color-golden)",
                padding: "3rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item xs={12}>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  HÉTFŐ
                </StyledInputLabel>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  {data?.dailyMenu1?.date}
                </StyledInputLabel>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>Leves</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu1S")}
                    {...register("dailyMenu1S")}
                  />
                  <p className="error">{errors.dailyMenu1S?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>A menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu1A")}
                    {...register("dailyMenu1A")}
                  />
                  <p className="error">{errors.dailyMenu1A?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>B menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu1B")}
                    {...register("dailyMenu1B")}
                  />
                  <p className="error">{errors.dailyMenu1B?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
                <Stack>
                  <StyledInputLabel>C menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu1C")}
                    {...register("dailyMenu1C")}
                  />
                  <p className="error">{errors.dailyMenu1C?.message}</p>
                </Stack>
              </Grid>
            </Grid>

            {/* kedd */}
            <Grid
              container
              item
              xs={8}
              spacing={3}
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "3rem",
                borderBottom: "2px solid var(--color-golden)",
                padding: "3rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item xs={12}>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--color-crimson)",
                    marginBottom: "2rem",
                  }}
                >
                  KEDD
                </StyledInputLabel>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  {data?.dailyMenu2?.date}
                </StyledInputLabel>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>Leves</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu2S")}
                    {...register("dailyMenu2S")}
                  />
                  <p className="error">{errors.dailyMenu2S?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>A menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu2A")}
                    {...register("dailyMenu2A")}
                  />
                  <p className="error">{errors.dailyMenu2A?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>B menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu2B")}
                    {...register("dailyMenu2B")}
                  />
                  <p className="error">{errors.dailyMenu2B?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
                <Stack>
                  <StyledInputLabel>C menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu2C")}
                    {...register("dailyMenu2C")}
                  />
                  <p className="error">{errors.dailyMenu2C?.message}</p>
                </Stack>
              </Grid>
            </Grid>

            {/* szerda */}
            <Grid
              container
              item
              xs={8}
              spacing={3}
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "3rem",
                borderBottom: "2px solid var(--color-golden)",
                padding: "3rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item xs={12}>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--color-crimson)",
                    marginBottom: "2rem",
                  }}
                >
                  SZERDA
                </StyledInputLabel>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  {data?.dailyMenu3?.date}
                </StyledInputLabel>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>Leves</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu3S")}
                    {...register("dailyMenu3S")}
                  />
                  <p className="error">{errors.dailyMenu3S?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>A menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu3A")}
                    {...register("dailyMenu3A")}
                  />
                  <p className="error">{errors.dailyMenu3A?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>B menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu3B")}
                    {...register("dailyMenu3B")}
                  />
                  <p className="error">{errors.dailyMenu3B?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
                <Stack>
                  <StyledInputLabel>C menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu3C")}
                    {...register("dailyMenu3C")}
                  />
                  <p className="error">{errors.dailyMenu3C?.message}</p>
                </Stack>
              </Grid>
            </Grid>

            {/* csütörtök */}
            <Grid
              container
              item
              xs={8}
              spacing={3}
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "3rem",
                borderBottom: "2px solid var(--color-golden)",
                padding: "3rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item xs={12}>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--color-crimson)",
                    marginBottom: "2rem",
                  }}
                >
                  CSÜTÖRTÖK
                </StyledInputLabel>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  {data?.dailyMenu4?.date}
                </StyledInputLabel>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>Leves</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu4S")}
                    {...register("dailyMenu4S")}
                  />
                  <p className="error">{errors.dailyMenu4S?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>A menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu4A")}
                    {...register("dailyMenu4A")}
                  />
                  <p className="error">{errors.dailyMenu4A?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>B menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu4B")}
                    {...register("dailyMenu4B")}
                  />
                  <p className="error">{errors.dailyMenu4B?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
                <Stack>
                  <StyledInputLabel>C menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu4C")}
                    {...register("dailyMenu4C")}
                  />
                  <p className="error">{errors.dailyMenu4C?.message}</p>
                </Stack>
              </Grid>
            </Grid>

            {/* péntek */}
            <Grid
              container
              item
              xs={8}
              spacing={3}
              alignItems="center"
              justifyContent="center"
              sx={{
                marginTop: "3rem",
                borderBottom: "2px solid var(--color-golden)",
                padding: "3rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item xs={12}>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                    color: "var(--color-crimson)",
                    marginBottom: "2rem",
                  }}
                >
                  PÉNTEK
                </StyledInputLabel>
                <StyledInputLabel
                  sx={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    color: "var(--color-crimson)",
                    marginBottom: "1rem",
                  }}
                >
                  {data?.dailyMenu5?.date}
                </StyledInputLabel>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>Leves</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu5S")}
                    {...register("dailyMenu5S")}
                  />
                  <p className="error">{errors.dailyMenu5S?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>A menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu5A")}
                    {...register("dailyMenu5A")}
                  />
                  <p className="error">{errors.dailyMenu5A?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10}>
                <Stack>
                  <StyledInputLabel>B menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu5B")}
                    {...register("dailyMenu5B")}
                  />
                  <p className="error">{errors.dailyMenu5B?.message}</p>
                </Stack>
              </Grid>
              <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
                <Stack>
                  <StyledInputLabel>C menü</StyledInputLabel>
                  <StyledTextField
                    variant="standard"
                    autoComplete="off"
                    InputLabelProps={{ ...inputLabelProps }}
                    InputProps={{ ...inputProps }}
                    defaultValue={getValues("dailyMenu5C")}
                    {...register("dailyMenu5C")}
                  />
                  <p className="error">{errors.dailyMenu5C?.message}</p>
                </Stack>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={3}>
                <Stack direction="column" mt={15} spacing={5}>
                  {show && (
                    <Button
                      type="submit"
                      endIcon={<SendIcon />}
                      sx={{
                        padding: "1.25rem 0",
                        fontSize: "1.25rem",
                        color: "var(--color-golden)",
                      }}
                    >
                      Adatok mentése
                    </Button>
                  )}
                  {show && (
                    <Button
                      component={Link}
                      to="/admin"
                      startIcon={<CancelIcon />}
                      sx={{
                        padding: "1.25rem 0",
                        fontSize: "1.25rem",
                        color: "var(--color-crimson)",
                      }}
                    >
                      Mégsem
                    </Button>
                  )}
                  <Snackbar
                    ref={snackbarRef}
                    message="SIKERES MENTÉS!"
                    type={SnackBarType.success}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      {!isData && (
        <div className="missingMenu">
          <h1 className="missingText">Érvénytelen azonosító!</h1>
        </div>
      )}
    </Box>
  );
};

export default EditMenu;
