import React from "react";
import PalyazatLogo from "../../assets/palyazat_logo.png";

import "./Tender.css";

const Tender = () => {
  return (
    <div className="app__tender section__padding app__bg">
      <div className="app__wrapper_info">
        <h1 className="app__tender-headtext">
          Kamatmentes újraindítási gyorskölcsön hitelprogram
        </h1>
        <h1 className="app__tender-text">
          Kedvezményezett neve: <span>Csáki Imre</span>
        </h1>
        <h1 className="app__tender-text">
          Projekt címe:{" "}
          <span>Kamatmentes újraindítási gyorskölcsön hitelprogram</span>
        </h1>
        <h1 className="app__tender-text">
          Visszatérítendő hitel összege: <span>10.000.000 Ft</span>
        </h1>
        <h1 className="app__tender-text">
          Projekt befejezésének tervezett napja: <span>2031. június 15.</span>
        </h1>
        <h1 className="app__tender-text">
          Projekt megvalósulási helyszíne:{" "}
          <span>6000 Kecskemét, Újerdő utca 27.</span>
        </h1>
        <h1 className="app__tender-text">
          Projekt azonosítószáma: <span>GINOP-S.1.1-21</span>
        </h1>

        <div className="palyazat__container">
          <img
            src={PalyazatLogo}
            className="palyazat__logo"
            alt="pályázat logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Tender;
