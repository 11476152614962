import React from "react";
import { Link } from "react-router-dom";

import "./Header.css";
import { images } from "../../constants";

const Header = () => (
  <div className="app__header section__padding app__bg" id="home">
    <div className="app__wrapper_info">
      <h1 className="app__header-h1">Ha enni muszáj, akkor Muszály!</h1>
      <p className="p__alt" style={{ margin: "2rem 0", textAlign: "justify" }}>
        Minden hétköznap 08.00-14.00 óráig házias ízekkel várjuk vendégeinket.
        Éttermünk kiváló helyszínt biztosít baráti, családi összejövetelekre,
        kisebb létszámú (60-70 fős) esküvőkre, egyéb eseményekre.
        <br />
        Étkezési utalvány elfogadóhely!
      </p>
      <Link to="/menu">
        <button className="custom__button small__button">Heti étlap</button>
      </Link>
    </div>
    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" />
    </div>
  </div>
);

export default Header;
