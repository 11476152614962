import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Gallery, Header, Contact, Tender } from "./container";
import {
  Navbar,
  SliderMenu,
  Admin,
  NewMenu,
  EditMenu,
  UpdatePrice,
  Login,
} from "./components";
import "./App.css";

function App() {
  const client = new QueryClient();

  const username = localStorage.getItem("username");

  const RequireAuth = ({ children }) => {
    return username ? children : <Navigate to="/login" />;
  };

  return (
    <div>
      <QueryClientProvider client={client}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Header />}></Route>
          <Route path="/menu" element={<SliderMenu />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/admin">
            <Route
              index
              element={
                <RequireAuth>
                  <Admin />
                </RequireAuth>
              }
            />
            <Route
              path="newMenu"
              element={
                <RequireAuth>
                  <NewMenu />
                </RequireAuth>
              }
            />
            <Route
              path="editMenu/:id"
              element={
                <RequireAuth>
                  <EditMenu />
                </RequireAuth>
              }
            />
            <Route
              path="updatePrice"
              element={
                <RequireAuth>
                  <UpdatePrice />
                </RequireAuth>
              }
            />
          </Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/tender" element={<Tender />}></Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>
        {/* <Footer /> */}
      </QueryClientProvider>
    </div>
  );
}

export default App;
