import React from "react";

import { FiFacebook } from "react-icons/fi";

import "./Contact.css";

const Contact = () => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="app__bg app__footer section__padding" id="contact">
      <div className="app__footer-links">
        <div className="app__footer-links_contact">
          <h1 className="app__footer-headtext">Kapcsolat</h1>
          <p
            className="p__alt"
            onClick={() => {
              openInNewTab("https://goo.gl/maps/gAqRFNPX1uT7Cctd8");
            }}
          >
            Kecskemét, Újerdő utca 27.
          </p>
          <p className="p__alt">
            <a href="tel:+3676323185">Rendelés / étterem: (76) 323-185</a>
          </p>
          <p className="p__alt">
            <a href="tel:+36309370811">Üzletvezető: (30) 937-0811</a>
          </p>
        </div>
        <div className="app__footer-links_logo">
          <div className="app__footer-logo">Muszály Vendéglő</div>
          <p className="p__alt">Ha enni muszáj, akkor Muszály!</p>
          <div className="app__footer-links_icons">
            <FiFacebook
              onClick={() => {
                openInNewTab("https://www.facebook.com/MuszalyVendeglo");
              }}
            />
          </div>
        </div>
        <div className="app__footer-links_work">
          <h1 className="app__footer-headtext">Nyitvatartás</h1>
          <p className="p__alt">Hétfő-péntek:</p>
          <p className="p__alt">08:00 - 14:00</p>
          <p className="p__alt">Szombat-vasárnap:</p>
          <p className="p__alt">zárva</p>
        </div>
      </div>
      <div className="footer__copyright">
        <p className="p__alt">
          Created by&nbsp;
          <span
            className="p__span"
            onClick={() => {
              openInNewTab("https://www.skasoft.hu");
            }}
          >
            SkaSoft
          </span>
          &nbsp;| All rights reserved!
        </p>
      </div>
    </div>
  );
};

export default Contact;
