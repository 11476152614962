import React from "react";

import "./MenuItem.css";

const MenuItem = ({ day, date, soup, menuA, menuB, menuC }) => (
  <div>
    <div className="card">
      <div className="card-top">
        <h1>{day}</h1>
        <h2>{date}</h2>
      </div>
      <div className="card-bottom">
        <p className="soup">{soup}</p>
        <p>{menuA}</p>
        <p>{menuB}</p>
        <p>{menuC}</p>
      </div>
    </div>
  </div>
);

export default MenuItem;
