import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Snackbar from "../../features/Snackbar";

import { Box, Grid, Button, Stack, TextField } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  StyledInputLabel,
  StyledTextField,
  inputProps,
  inputLabelProps,
  popperProps,
  dtpProps,
} from "../../components/Styling/CustomStyling";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import "dayjs/locale/hu";
import moment from "moment";

import { weekNumber } from "weeknumber";

import "./NewMenu.css";

const NewMenu = () => {
  const schema = yup.object().shape({
    year: yup.string().required(),
    week: yup.string().required(),
    dateRange: yup.string().required(),
    startDate: yup
      .date("A mező kitöltése kötelező!")
      .typeError("A mező kitöltése kötelező!")
      .required("A mező kitöltése kötelező!"),

    dailyMenu1S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu1C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu2S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu2C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu3S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu3C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu4S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu4C: yup.string().required("A mező kitöltése kötelező!"),

    dailyMenu5S: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5A: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5B: yup.string().required("A mező kitöltése kötelező!"),
    dailyMenu5C: yup.string().required("A mező kitöltése kötelező!"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: dayjs(new Date()).format("YYYY-MM-DD"),
      // year: "2022",
      // week: "47",
      // dateRange: "2022. november 21. - 2022. november 27.",
    },
  });

  const navigate = useNavigate();

  const snackbarRef = useRef(null);
  const SnackBarType = {
    success: "success",
    fail: "fail",
  };

  //submit form
  const onSubmit = async (data) => {
    setShow(false);
    snackbarRef.current.show();
    try {
      axios
        .post("https://muszalyvendeglo.herokuapp.com/createMenu", {
          year: data.year,
          week: data.week,
          dateRange: data.dateRange,
          dailyMenu1: {
            day: "Hétfő",
            date: day1Date,
            soup: data.dailyMenu1S,
            menuA: data.dailyMenu1A,
            menuB: data.dailyMenu1B,
            menuC: data.dailyMenu1C,
          },
          dailyMenu2: {
            day: "Kedd",
            date: day2Date,
            soup: data.dailyMenu2S,
            menuA: data.dailyMenu2A,
            menuB: data.dailyMenu2B,
            menuC: data.dailyMenu2C,
          },
          dailyMenu3: {
            day: "Szerda",
            date: day3Date,
            soup: data.dailyMenu3S,
            menuA: data.dailyMenu3A,
            menuB: data.dailyMenu3B,
            menuC: data.dailyMenu3C,
          },
          dailyMenu4: {
            day: "Csütörtök",
            date: day4Date,
            soup: data.dailyMenu4S,
            menuA: data.dailyMenu4A,
            menuB: data.dailyMenu4B,
            menuC: data.dailyMenu4C,
          },
          dailyMenu5: {
            day: "Péntek",
            date: day5Date,
            soup: data.dailyMenu5S,
            menuA: data.dailyMenu5A,
            menuB: data.dailyMenu5B,
            menuC: data.dailyMenu5C,
          },
        })
        .then((response) => {
          {
            snackbarRef.current.show();
          }
          setTimeout(() => {
            navigate("/admin");
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const dow = new Date();
  let year = dow.getFullYear();
  let week = weekNumber(dow);
  const today = moment();
  const startDateString = dayjs(today.startOf("isoWeek"))
    .format("YYYY. MMMM DD.")
    .toLowerCase();
  const endDateString = dayjs(today.endOf("isoWeek"))
    .format("YYYY. MMMM DD.")
    .toLowerCase();
  const dateString = startDateString + " - " + endDateString;

  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [actualYear, setActualYear] = useState(year);
  const [actualWeek, setActualWeek] = useState(week);
  const [dateRange, setDateRange] = useState(dateString);

  let shiftToday = dayjs(moment().startOf("isoWeek"));
  let setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
  const [day1Date, setDay1Date] = useState(setDate);

  shiftToday = dayjs(moment().startOf("isoWeek").add(1, "d"));
  setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
  const [day2Date, setDay2Date] = useState(setDate);

  shiftToday = dayjs(moment().startOf("isoWeek").add(2, "d"));
  setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
  const [day3Date, setDay3Date] = useState(setDate);

  shiftToday = dayjs(moment().startOf("isoWeek").add(3, "d"));
  setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
  const [day4Date, setDay4Date] = useState(setDate);

  shiftToday = dayjs(moment().startOf("isoWeek").add(4, "d"));
  setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
  const [day5Date, setDay5Date] = useState(setDate);

  const [locale, setLocale] = useState("hu");

  const [show, setShow] = useState(true);

  const handleStartDate = (newValue) => {
    const dow = new Date(newValue);
    let year = dow.getFullYear();
    let week = weekNumber(dow);
    const startDateString = dayjs(newValue?.startOf("week")).format(
      "YYYY. MMMM DD."
    );
    const endDateString = dayjs(newValue?.endOf("week")).format(
      "YYYY. MMMM DD."
    );
    const dateString = startDateString + " - " + endDateString;
    setActualYear(year);
    setActualWeek(week);
    setDateRange(dateString);
    setStartDate(newValue);
    setValue("startDate", dayjs(newValue).format("YYYY-MM-DD"));
    setValue("year", year);
    setValue("week", week);
    setValue("dateRange", dateString);

    let shiftToday = dayjs(newValue.startOf("isoWeek"));
    let setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
    setDay1Date(setDate);

    shiftToday = dayjs(newValue.startOf("isoWeek").add(1, "d"));
    setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
    setDay2Date(setDate);

    shiftToday = dayjs(newValue.startOf("isoWeek").add(2, "d"));
    setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
    setDay3Date(setDate);

    shiftToday = dayjs(newValue.startOf("isoWeek").add(3, "d"));
    setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
    setDay4Date(setDate);

    shiftToday = dayjs(newValue.startOf("isoWeek").add(4, "d"));
    setDate = dayjs(shiftToday).format("YYYY. MMMM DD.").toLowerCase();
    setDay5Date(setDate);
  };

  return (
    <Box className="newMenuContainer app__bg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={4}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <Stack>
                <StyledInputLabel>
                  Válaszd ki a rögzítendő hetet
                </StyledInputLabel>
                <DesktopDatePicker
                  inputFormat="YYYY. MMMM DD."
                  value={startDate}
                  renderInput={(params) => <TextField {...params} />}
                  InputProps={{ ...dtpProps }}
                  InputLabelProps={{ ...inputLabelProps }}
                  PopperProps={{ ...popperProps }}
                  disableHighlightToday
                  disableMaskedInput
                  views={["day"]}
                  {...register("startDate")}
                  onChange={handleStartDate}
                />
                <p>{errors.startDate?.message}</p>
              </Stack>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack>
              <StyledInputLabel>Év</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                readOnly
                value={actualYear}
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("year")}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack>
              <StyledInputLabel>Hét</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                readOnly
                value={actualWeek}
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("week")}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack>
              <StyledInputLabel>Dátum</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                readOnly
                value={dateRange}
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("dateRange")}
              />
            </Stack>
          </Grid>

          {/* hétfő */}
          <Grid
            container
            item
            xs={12}
            md={8}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "3rem",
              borderBottom: "2px solid var(--color-golden)",
              padding: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
              >
                HÉTFŐ
              </StyledInputLabel>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
                {...register("dailyMenu1Day")}
              >
                {day1Date}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>Leves</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu1S")}
                />
                <p className="error">{errors.dailyMenu1S?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>A menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu1A")}
                />
                <p className="error">{errors.dailyMenu1A?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>B menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu1B")}
                />
                <p className="error">{errors.dailyMenu1B?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
              <Stack>
                <StyledInputLabel>C menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu1C")}
                />
                <p className="error">{errors.dailyMenu1C?.message}</p>
              </Stack>
            </Grid>
          </Grid>

          {/* kedd */}
          <Grid
            container
            item
            xs={8}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "3rem",
              borderBottom: "2px solid var(--color-golden)",
              padding: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--color-crimson)",
                  marginBottom: "2rem",
                }}
              >
                KEDD
              </StyledInputLabel>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
              >
                {day2Date}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>Leves</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu2S")}
                />
                <p className="error">{errors.dailyMenu2S?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>A menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu2A")}
                />
                <p className="error">{errors.dailyMenu2A?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>B menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu2B")}
                />
                <p className="error">{errors.dailyMenu2B?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
              <Stack>
                <StyledInputLabel>C menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu2C")}
                />
                <p className="error">{errors.dailyMenu2C?.message}</p>
              </Stack>
            </Grid>
          </Grid>

          {/* szerda */}
          <Grid
            container
            item
            xs={8}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "3rem",
              borderBottom: "2px solid var(--color-golden)",
              padding: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--color-crimson)",
                  marginBottom: "2rem",
                }}
              >
                SZERDA
              </StyledInputLabel>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
              >
                {day3Date}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>Leves</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu3S")}
                />
                <p className="error">{errors.dailyMenu3S?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>A menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu3A")}
                />
                <p className="error">{errors.dailyMenu3A?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>B menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu3B")}
                />
                <p className="error">{errors.dailyMenu3B?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
              <Stack>
                <StyledInputLabel>C menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu3C")}
                />
                <p className="error">{errors.dailyMenu3C?.message}</p>
              </Stack>
            </Grid>
          </Grid>

          {/* csütörtök */}
          <Grid
            container
            item
            xs={8}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "3rem",
              borderBottom: "2px solid var(--color-golden)",
              padding: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--color-crimson)",
                  marginBottom: "2rem",
                }}
              >
                CSÜTÖRTÖK
              </StyledInputLabel>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
              >
                {day4Date}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>Leves</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu4S")}
                />
                <p className="error">{errors.dailyMenu4S?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>A menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu4A")}
                />
                <p className="error">{errors.dailyMenu4A?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>B menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu4B")}
                />
                <p className="error">{errors.dailyMenu4B?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
              <Stack>
                <StyledInputLabel>C menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu4C")}
                />
                <p className="error">{errors.dailyMenu4C?.message}</p>
              </Stack>
            </Grid>
          </Grid>

          {/* péntek */}
          <Grid
            container
            item
            xs={8}
            spacing={3}
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: "3rem",
              borderBottom: "2px solid var(--color-golden)",
              padding: "3rem",
              borderRadius: "0.5rem",
            }}
          >
            <Grid item xs={12}>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  color: "var(--color-crimson)",
                  marginBottom: "2rem",
                }}
              >
                PÉNTEK
              </StyledInputLabel>
              <StyledInputLabel
                sx={{
                  textAlign: "center",
                  fontSize: "1.2rem",
                  color: "var(--color-crimson)",
                  marginBottom: "1rem",
                }}
              >
                {day5Date}
              </StyledInputLabel>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>Leves</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu5S")}
                />
                <p className="error">{errors.dailyMenu5S?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>A menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu5A")}
                />
                <p className="error">{errors.dailyMenu5A?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <Stack>
                <StyledInputLabel>B menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu5B")}
                />
                <p className="error">{errors.dailyMenu5B?.message}</p>
              </Stack>
            </Grid>
            <Grid item xs={10} sx={{ marginBottom: "3rem" }}>
              <Stack>
                <StyledInputLabel>C menü</StyledInputLabel>
                <StyledTextField
                  variant="standard"
                  autoComplete="off"
                  InputLabelProps={{ ...inputLabelProps }}
                  InputProps={{ ...inputProps }}
                  {...register("dailyMenu5C")}
                />
                <p className="error">{errors.dailyMenu5C?.message}</p>
              </Stack>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <Stack direction="column" mt={15} spacing={5}>
                {show && (
                  <Button
                    type="submit"
                    endIcon={<SendIcon />}
                    sx={{
                      padding: "1.25rem 0",
                      fontSize: "1.25rem",
                      color: "var(--color-golden)",
                    }}
                  >
                    Adatok mentése
                  </Button>
                )}
                {show && (
                  <Button
                    component={Link}
                    to="/admin"
                    startIcon={<CancelIcon />}
                    sx={{
                      padding: "1.25rem 0",
                      fontSize: "1.25rem",
                      color: "var(--color-crimson)",
                    }}
                  >
                    Mégsem
                  </Button>
                )}
                <Snackbar
                  ref={snackbarRef}
                  message="SIKERES MENTÉS!"
                  type={SnackBarType.success}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default NewMenu;
