import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Box, Grid, Button, Stack } from "@mui/material";

import {
  StyledInputLabel,
  StyledTextField,
  inputProps,
  inputLabelProps,
} from "../../components/Styling/CustomStyling";

import Snackbar from "../../features/Snackbar";

import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";

import "./UpdatePrice.css";
import { useEffect } from "react";
import { getValue } from "@mui/system";
import { useQuery } from "@tanstack/react-query";

const UpdatePrice = () => {
  const navigate = useNavigate();

  const { data } = useQuery(["getPrice"], async () => {
    return axios
      .get(`https://muszalyvendeglo.herokuapp.com/getPrice`)
      .then((res) => res.data);
  });

  const snackbarRef = useRef(null);
  const SnackBarType = {
    success: "success",
    fail: "fail",
  };
  const [show, setShow] = useState(true);

  const schema = yup.object().shape({
    price: yup.string().required("A mező kitöltése kötelező!"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submit form
  const onSubmit = async (data) => {
    setShow(false);
    snackbarRef.current.show();
    try {
      axios
        .put("https://muszalyvendeglo.herokuapp.com/updatePrice", {
          price: data.price,
        })
        .then((response) => {
          snackbarRef.current.show();
          setTimeout(() => {
            navigate("/admin");
          }, 2000);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setValue("price", data[0].price);
    }
  }, [data]);

  return (
    <Box className="updatePriceContainer app__bg" sx={{ paddingTop: "12rem" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={2}>
            <Stack>
              <StyledInputLabel>Menü ár</StyledInputLabel>
              <StyledTextField
                variant="standard"
                autoComplete="off"
                value={getValue("price")}
                InputLabelProps={{ ...inputLabelProps }}
                InputProps={{ ...inputProps }}
                {...register("price")}
              />
              <p className="error">{errors.price?.message}</p>
            </Stack>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={3}>
              <Stack direction="column" mt={10} spacing={5}>
                {show && (
                  <Button
                    type="submit"
                    endIcon={<SendIcon />}
                    sx={{
                      padding: "1.25rem 0",
                      fontSize: "1.25rem",
                      color: "var(--color-golden)",
                    }}
                  >
                    Adatok mentése
                  </Button>
                )}
                {show && (
                  <Button
                    component={Link}
                    to="/admin"
                    startIcon={<CancelIcon />}
                    sx={{
                      padding: "1.25rem 0",
                      fontSize: "1.25rem",
                      color: "var(--color-crimson)",
                    }}
                  >
                    Mégsem
                  </Button>
                )}
                <Snackbar
                  ref={snackbarRef}
                  message="SIKERES MENTÉS!"
                  type={SnackBarType.success}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default UpdatePrice;
