import React, { useState } from "react";
import { Link } from "react-router-dom";

import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurant } from "react-icons/md";

import PalyazatLogo from "../../assets/palyazat_logo.png";
import icon from "../../assets/icon.png";

import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="app__navbar app__bg">
      <div className="app__navbar-logo-container">
        <img src={icon} className="icon" alt="icon" />
        <div className="app__navbar-logo">Muszály Vendéglő</div>
        <img src={icon} className="icon" alt="icon" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__alt">
          <Link to="/">Kezdőlap</Link>
        </li>
        <li className="p__alt">
          <Link to="/menu">Heti étlap</Link>
        </li>
        <li className="p__alt">
          <Link to="/gallery">Galéria</Link>
        </li>
        <li className="p__alt">
          <Link to="/contact">Kapcsolat</Link>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="var(--color-golden)"
          fontSize={27}
          cursor="pointer"
          onClick={() => {
            setToggleMenu(true);
          }}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurant
              fontSize={27}
              className="overlay__close"
              onClick={() => {
                setToggleMenu(false);
              }}
            />
            <ul className="app__navbar-smallscreen_links">
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/">Kezdőlap</Link>
              </li>
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/menu">Heti étlap</Link>
              </li>
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/gallery">Galéria</Link>
              </li>
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/contact">Kapcsolat</Link>
              </li>
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                <Link to="/tender">Pályázat</Link>
              </li>
              <li
                className="p__alt"
                onClick={() => {
                  setToggleMenu(false);
                }}
              ></li>
            </ul>
          </div>
        )}
        ;
      </div>
      <div className="palyazat__container">
        <Link to="/tender">
          <img
            src={PalyazatLogo}
            className="palyazat__logo"
            alt="pályázat logo"
          />
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
