import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomSnackbar = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    show() {
      setOpen(true);
    },
  }));

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={props.message}
      >
        <Alert
          onClose={handleClose}
          severity={props.type}
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
});

export default CustomSnackbar;
